$(".box.about").click(function() {
   $("#about").fadeIn();
});

$(".close").click(function() {
   $(this).parent().parent().fadeOut();
});

$(".box.three-d").click(function() {
   $("#three-d").fadeIn();
});

$(".box.coll").click(function() {
   $("#collages").fadeIn();
});

$(".box.typo").click(function() {
   $("#typo").fadeIn();
});

$(".contact").click(function() {
   $("#contact").fadeIn();
});
